import React from "react"
import {graphql} from "gatsby"

import imgPage from "../images/headers/Logos_Ale_FBO_Management_Blue.svg"
import Layout from "../components/layout.en"
import SEO from "../components/seo"
import style from "./fbo-management.module.scss"
import Header from "../components/header";

import Frecuencia from "../images/icons/icon_frecuencia.svg"
//import MembershipLogo from "../images/icons/ale_membership.svg"
import AleLogo from "../images/icons/footer_logo.svg"
import Matriculas from "../images/icons/icon_matriculada.svg"
import Calendar from "../images/icons/icon_calendario.svg"
import Juridico from "../images/icons/icon_juridico.svg"
import Img from "gatsby-image";
import {ParallaxBanner} from "react-scroll-parallax";
import Form from "../components/form.en";

export default ({data}) => {
    const extraInfo =
        [
            {name: '', mail: 'info@ale.mx', phone: '55 4202 0920'}

        ];
    return (
        <Layout extraInfo={extraInfo}>
            <Header LogoPage={imgPage}/>
            <SEO title="FBO & Management Aerolineas Ejecutivas"/>

            <div className={style.fboCover}>
                <Img fluid={data.portada.childImageSharp.fluid}/>
            </div>
            <Form options={['FBO: Aircraft Management/Hangar']}/>

            <div className={style.fboInfo}>
                <h1>FBO & Management</h1>
                <p>At Ale FBO & Management we take care of the management and custody of your aircraft, we are the first
                    private aviation FBO that has an international lounge in México, where you can do your international
                    arrivals and customs service directly in our facilities.</p>
            </div>

            <div className={style.fboMembership}>
                <div className={style.top}>
                    <div className={style.fboMembershipLogo}>
                        <AleLogo/>
                        <h2>Membership</h2>
                    </div>
                </div>
                <div className={style.bottom}>
                    <div className={style.left}>
                        <p><b>WHAT IS ALE MEMBERSHIP?</b></p>
                        <p>It is an exclusive product designed specifically to provide comprehensive solutions to
                            aircraft owners whose operation is based on business and leisure travel.</p>
                    </div>
                    <div className={style.right}>
                        <p><b>HOW DOES THE MEMBERSHIP WORK?</b></p>
                        <p>A monthly management fee is established, through which our team of professionals is
                            responsible for monitoring and following up on all the administrative, operational and
                            maintenance needs of your aircraft, so you can focus on your business while enjoying
                            worry-free travel.</p>
                    </div>
                </div>
            </div>

            <div className={style.fboServices}>
                <h2>OUR SERVICES</h2>
                <div className={style.top}>
                    <p><b>At ALE FBO & Management we take care of the management and custody of your aircraft.</b></p>
                    <p>A monthly management fee is established, through which our team of professionals is responsible
                        for monitoring and following up on all the administrative, operational and maintenance needs of
                        your aircraft, so you can focus on your business while enjoying worry-free travel.</p>
                </div>
                <div className={style.bottom}>
                    <div className={style.left}>
                        <Frecuencia/>
                        <p><b>FREQUENCY</b></p>
                        <p>131.7 en MTY / TLC</p>
                    </div>
                    <div className={style.right}>
                        <p><b>WE HAVE AN INTERNATIONAL LOUNGE IN OPERATION</b></p>
                        <p>Now your international trips will be faster!</p>
                        <p>We are the first private aviation FBO to have an international lounge in México.</p>
                        <p>Our benefits include:</p>
                        <ul>
                            <li><p><b>Customs</b></p></li>
                            <li><p><b>Migration</b></p></li>
                            <li><p><b>Sagarpa</b></p></li>
                            <li><p><b>Federal Police</b></p></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={style.fboImages}>
                <ParallaxBanner
                    className={style.fotoParallax}
                    layers={[{image: data.foto1.publicURL, amount: 1.0}]}
                    style={{maxHeight: '400px'}}
                >
                </ParallaxBanner>
                <div className={style.title}>
                    <p>TRAFFIC AND DISPATCH SERVICES</p>
                    <span> </span>
                </div>
                <div className={style.infoBlock}>
                    <div className={style.info}>
                        <ul>
                            <li><p>ATTENTION OF NATIONAL AND INTERNATIONAL DEPARTURES AND ARRIVALS IN TOLUCA AND MONTERREY.</p>
                            </li>
                            <li><p>HOTEL AND CAR RESERVATIONS.</p></li>
                            <li><p>MIGRATION AND CUSTOMS COORDINATION.</p></li>
                            <li><p>WEATHER INFORMATION.</p></li>
                            <li><p>FLIGHT PLANS COORDINATION.</p></li>
                            <li><p>AIRPORT LETTERS (JEPPESEN).</p></li>
                        </ul>
                    </div>
                    <Img className={style.img} fluid={data.foto2.childImageSharp.fluid}/>
                </div>
                <div className={style.title}>
                    <p>RAMP SERVICES</p>
                    <span> </span>
                </div>
                <div className={style.infoBlock}>
                    <Img className={style.img} fluid={data.foto3.childImageSharp.fluid}/>
                    <div className={style.info}>
                        <ul>
                            <li><p>START UP WITH AUXILIARY PLANT.</p></li>
                            <li><p>BASIC ADMINISTRATIVE OFFICE (COFFEE, ICE AND NEWSPAPERS).</p></li>
                            <li><p>INTERIOR AND EXTERIOR AIRCRAFT CLEANINGS.</p></li>
                            <li><p>TOWING FROM AND TO THE HANGAR</p></li>
                            <li><p>FUEL SERVICE.</p></li>
                            <li><p>EXTRAORDINARY ADMINISTRATIVE OFFICE SERVICE.</p></li>

                        </ul>
                    </div>
                </div>
            </div>

            <div className={style.fboAOC}>
                <h2>AOC INCLUSION</h2>
                <p><b>At ALE FBO & Management we take care of the management of your aircraft</b></p>
                <p>The equipment must necessarily be included in ALE's AOC (Air Operator's Certificate) in order to enjoy the programs, benefit, rates and more than 50 years expertise in the aviation industry.</p>
                <div className={style.needs}>
                    <div className={style.item}>
                        <Matriculas/>
                        <p>Preferably the aircrafts must be registered as "XA", as it is the structure that has the greatest tax and operational benefits.</p>
                    </div>
                    <div className={style.item}>
                        <Calendar/>
                        <p>Inclusion requires an approximate period of 12 to 14 weeks.</p>
                    </div>
                    <div className={style.item}>
                        <Juridico/>
                        <p>During the process, our legal and operations engineering team provide the progress of each process.</p>
                    </div>
                </div>
            </div>
            <Form align="bottom" options={['FBO: Aircraft Management/Hangar']}/>
            <div className={style.fboImages}>
                <Img className={style.img} fluid={data.foto4.childImageSharp.fluid}/>
            </div>

        </Layout>
    )
}

export const query = graphql`
    query ImagesFBOEn {
    portada: file(relativePath: {eq: "fbo/foto_portada_2023.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto1: file(relativePath: {eq: "fbo/FBO1.jpg"}) {
        publicURL
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2: file(relativePath: {eq: "fbo/FBO2.jpg"}) {
        publicURL
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto3: file(relativePath: {eq: "fbo/FBO3.jpg"}) {
        publicURL
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto4: file(relativePath: {eq: "fbo/FBO4.jpg"}) {
        publicURL
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
   
}`;



